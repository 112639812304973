import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";
import { API_URL, EXTERNAL_API_URL, DOMAIN } from "@/const";
export default {
  data() {
    return {
      validate: {
        legalPersonalityTypeId: { isValid: true, errMsg: "" },
        companyName: { isValid: true, errMsg: "" },
        zipCode: { isValid: true, errMsg: "" },
        prefecturesId: { isValid: true, errMsg: "" },
        address: { isValid: true, errMsg: "" },
        buildingName: { isValid: true, errMsg: "" },
        tel: { isValid: true, errMsg: "" },
        fax: { isValid: true, errMsg: "" },
        staffName: { isValid: true, errMsg: "" },
        staffMail: { isValid: true, errMsg: "" },
        businessCode: { isValid: true, errMsg: "" },
        disposalLicenceCreateList: [
          {
            licenceNo: { isValid: true, errMsg: "" },
            cityName: { isValid: true, errMsg: "" },
            licenceExpiredDate: { isValid: true, errMsg: "" },
            targetJwnetItemList: { isValid: true, errMsg: "" },
            disposalWayList: [
              {
                disposalWayTypeId: { isValid: true, errMsg: "" },
                disposalDetailWay: { isValid: true, errMsg: "" },
              },
            ],
          },
        ],
      },
      dummyValidate: {
        disposalLicence: {
          cityName: { isValid: true, errMsg: "" },
          licenceNo: { isValid: true, errMsg: "" },
          licenceExpiredDate: { isValid: true, errMsg: "" },
          targetJwnetItemList: { isValid: true, errMsg: "" },
          disposalWayList: [
            {
              disposalWayTypeId: { isValid: true, errMsg: "" },
              disposalDetailWay: { isValid: true, errMsg: "" },
            },
          ],
        },
        disposalWay: {
          disposalWayTypeId: { isValid: true, errMsg: "" },
          disposalDetailWay: { isValid: true, errMsg: "" },
        }
      }
    };
  },
  methods: {
    getLicenceForm(licenceIndex) {
      return this.validate.disposalLicenceCreateList[licenceIndex];
    },
    getDisposalForm(licenceIndex, disposalIndex) {
      return this.getLicenceForm(licenceIndex).disposalWayList[disposalIndex];
    },

    async check() {
      let isValidForm = true;
      const v = this.validate;
      const f = this.form;
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9]*$/);

      if (!v.legalPersonalityTypeId.isValid) {
        v.legalPersonalityTypeId.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.companyName.isValid) {
        v.companyName.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.zipCode.isValid) {
        v.zipCode.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      } else {
        const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${this.form.addressInfo.zipCode}`;
          await axios
            .get(url, {adapter: axiosJsonpAdapter})
            .then((res) => {
              const results = res.data.results;
              if (results != null) {
                if (v.prefecturesId.isValid) {
                  var prefectureFlg = false;
                  results.forEach((address) => {
                    if (this.form.addressInfo.selectedPrefectureTemp === address.address1) {
                      prefectureFlg = true;
                    }
                  });
                  if (!prefectureFlg) {
                    v.prefecturesId.errMsg = "郵便番号と都道府県が一致していません。";
                    isValidForm = false;
                  }
                }
                if (v.address.isValid) {
                  var addressFlg = false;
                  results.forEach((address) => {
                    if (this.form.addressInfo.address === address.address2) {
                      addressFlg = true;
                    }
                  });
                  if (!addressFlg) {
                    v.address.errMsg = "郵便番号と市区町村が一致していません。";
                    isValidForm = false;
                  }
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
      }
      if (!v.prefecturesId.isValid) {
        v.prefecturesId.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.address.isValid) {
        v.address.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (this.form.addressInfo.buildingName && !v.buildingName.isValid) {
        v.buildingName.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.tel.isValid) {
        v.tel.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (this.form.fax && !v.fax.isValid) {
        v.fax.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.staffName.isValid) {
        v.staffName.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.staffMail.isValid) {
        v.staffMail.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }

      if (f.businessCode) {
        if (f.businessCode.length != 6 || !halfAlphaRegexp.test(f.businessCode)) {
          v.businessCode.errMsg = "半角6文字で入力してください。";
          isValidForm = false;
        }
      }

      // 2021.09.15 許可証の任意対応
      if (this.form.isLicence) {
        for (let i = 0; i < v.disposalLicenceCreateList.length; i++) {
          const licenceV = v.disposalLicenceCreateList[i],
            licenceForm = this.form.disposalLicenceCreateList[i];

          if (!licenceV.licenceNo.isValid) {
            licenceV.licenceNo.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          } else if (licenceForm.wasteTypeId === DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE) {
            // 産業廃棄物の許可証の場合
            await axios
              .get(API_URL.LICENCE.MUNICIPALITY + `?headNo=${licenceForm.licenceNo.substr(0, 3)}`)
              .catch((err) => {
                console.log(err);
                licenceV.licenceNo.errMsg = "許可証番号が正しくありません。正しい許可証番号を入力してください。";
                isValidForm = false;
              });
          }
          if (!licenceV.cityName.isValid) {
            licenceV.cityName.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          }
          if (licenceV.wasteTypeId === DOMAIN.WASTE_TYPE.COMMON_WASTE && !licenceV.cityName) {
            licenceV.cityName.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          }
          if (!licenceV.licenceExpiredDate.isValid) {
            licenceV.licenceExpiredDate.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          }
          if (!licenceForm.targetJwnetItemList.length) {
            licenceV.targetJwnetItemList.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          }
          licenceV.disposalWayList.forEach((disposalV) => {
            if (!disposalV.disposalWayTypeId.isValid) {
              disposalV.disposalWayTypeId.errMsg = "正しい値を入力してください。";
              isValidForm = false;
            }
          });
        }
      }

      return isValidForm;
    },

    async runValidate() {
      let isValidForm = false;
      isValidForm = await this.check();

      return isValidForm;
    },
  },
};
