export default {
  data() {
    return {
      form: {
        businessCode: null,
        legalPersonalityTypeId: null,
        companyName: "",
        addressInfo: {
          zipCode: "",
          prefecturesId: null,
          address: "",
          buildingName: "",
        },
        tel: null,
        fax: null,
        staffName: "",
        staffMail: "",
        cbaItemIdList: [],
        remarks: "",
        isLicence: true,
        disposalLicenceCreateList: [
          {
            wasteTypeId: 1,
            cityName: null,
            licenceNo: "",
            licenceExpiredDate: "",
            isExcellent: false,
            targetJwnetItemList: [],
            licenceFilePath: null,
            licenceFileName: null,
            disposalWayList: [
              {
                disposalWayTypeId: null,
                disposalDetailWay: "",
              },
            ],
          }
        ],
        commonWasteDisposalLicenceCreateList: [],
      },
      dummyForm: {
        cbaItem: {
          cbaItemId: null,
        },
        disposalLicence: {
          wasteTypeId: 1,
          cityName: null,
          licenceNo: "",
          licenceExpiredDate: "",
          isExcellent: false,
          targetJwnetItemList: [],
          licenceFilePath: null,
          licenceFileName: null,
          disposalWayList: [
            {
              disposalWayTypeId: null,
              disposalDetailWay: "",
            },
          ],
        },
        disposalWay: {
          disposalWayTypeId: null,
          disposalDetailWay: "",
        }
      },
      duplicationNameCheckInfoList: [],
    };
  },
};
